import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { H, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { FLOW_PROBATE_URL } from 'config'
import QuoteButton from '../QuoteButton'

const StyledQuoteCTAWrapper = styled(Wrapper)`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${GTR.M};
  padding-top: ${GTR.M};

  margin: auto;

  ${screenMax.m`
    padding-left: 0;
    padding-right: 0;
  `}

  ${screenMax.s`
    text-align: center;
  `}
`

const StyledH = styled(H)`
  ${screenMax.m`
    font-size: 18px;
  `}
`

const QuoteCTASection = (): ReactElement => (
  <StyledQuoteCTAWrapper container centered>
    <StyledH tag="h3" size="S" margin={['M', 0]}>
      Get your free quote online
    </StyledH>
    <div>
      <QuoteButton
        buttonText="Get a quote in 5 minutes"
        buttonType="White"
        linkPath={FLOW_PROBATE_URL}
        showArrow
      />
    </div>
  </StyledQuoteCTAWrapper>
)

export default QuoteCTASection
