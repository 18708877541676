import {
  ROOT_URL,
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
  PROBATE_WORKING_DAYS_GUARANTEE,
  PROBATE_SIMPLE_FEE,
} from 'config'
import PATHS from 'lib/navigation/paths'

import FAREWILL_JSONLD from './farewill'

export default {
  '@context': 'http://schema.org/',
  '@type': 'Product',
  name: 'Probate',
  brand: FAREWILL_JSONLD,
  image: `${ROOT_URL}/logos/share.jpg`,
  description: `We’ll prepare your application in ${PROBATE_WORKING_DAYS_GUARANTEE} working days. Fixed, upfront cost and friendly, specialist support. We take away the worry of filling in probate forms.`,
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: TRUSTPILOT_RATING_OUT_OF_5,
    reviewCount: TRUSTPILOT_REVIEWS_TOTAL,
    bestRating: 5,
    worstRating: 1,
  },
  offers: {
    '@type': 'Offer',
    priceCurrency: 'GBP',
    price: PROBATE_SIMPLE_FEE,
    priceValidUntil: '2020-11-05',
    url: `${ROOT_URL}${PATHS.PROBATE.OVERVIEW}`,
    seller: FAREWILL_JSONLD,
  },
}
