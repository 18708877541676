import { PROBATE_REGISTRY_FEE } from 'config'

export const PROBATE_FEATURES = {
  ESSENTIAL_PROBATE: [
    {
      title: 'Submit the grant of probate application',
      items: [],
    },
  ],
  COMPLETE_PROBATE: [
    {
      title: 'Assess and value the estate',
      items: [],
    },
    {
      title: 'Submit the grant of probate application',
      items: [],
    },
    {
      title: 'Close accounts, manage property, pay taxes and more',
      items: [],
    },
  ],
}

export const EXTRA_COSTS = {
  ESSENTIAL_PROBATE: `There’s a probate registry fee of £${PROBATE_REGISTRY_FEE}, and copies of the grant of probate cost £1.50 each.`,
  COMPLETE_PROBATE: `There’s a probate registry fee of £${PROBATE_REGISTRY_FEE}, and copies of the grant of probate cost £1.50 each. Other standard fees will apply.`,
}

export const EXTRA_INFO = {
  ESSENTIAL_PROBATE: `contact relevant organisations about assets and debts; gather documentation and verify who is to inherit; handle the estate administration; Close accounts, manage property, pay taxes and more.`,
}
