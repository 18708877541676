import React, {
  createRef,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import { debounce } from 'lodash-es'

import { Wrapper, H, P, Grid, Image, Divider } from '@farewill/ui'
import { COLOR, FONT, BORDER_RADIUS, BREAKPOINT } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { Columns } from '@farewill/ui/components/Grid/types'

import { PROBATE_SIMPLE_FEE, PROBATE_ESTATE_ADMIN_FEE } from 'config'
import BreakpointSwitch from 'components/BreakpointSwitch'
import WrapperWithEdge from 'components/WrapperWithEdge'
import { PROBATE_FEATURES, EXTRA_COSTS, EXTRA_INFO } from './constants'
import Option from './Option'
import QuoteCTASection from './QuoteCTASection'

const StyledOptions = styled(WrapperWithEdge)`
  position: relative;
  overflow: hidden;
`

const StyledTextureLeft = styled(Image)`
  position: absolute;
  pointer-events: none;
  bottom: 15%;
  left: 0;

  ${screenMax.m`
    display: none;
  `}
`

const StyledTextureRight = styled(Image)`
  position: absolute;
  pointer-events: none;
  top: 50px;
  right: 0;

  ${screenMax.m`
    display: none;
  `};
`

const StyledExtraCosts = styled(Wrapper)`
  & > * {
    display: inline;
  }
`

const StyledExtraInfo = styled(Wrapper)`
  border-radius: ${BORDER_RADIUS.M};
  border-color: ${COLOR.GREY.DARK};

  & > * {
    display: inline;
  }
`

const StyledWrapper = styled(Wrapper)`
  padding-bottom: 0;
`

const StyledGridItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledH = styled(H)`
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const Options = ({
  description,
  descriptionWidthInColumns = 6,
}: {
  description?: ReactNode
  descriptionWidthInColumns?: Columns
}): ReactElement | null => {
  const descriptionRefEssentialProbate = createRef<HTMLDivElement>()
  const descriptionRefComplexProbate = createRef<HTMLDivElement>()
  const [descriptionBoxHeight, setDescriptionBoxHeight] = useState<number>()

  useEffect(() => {
    const handleDescriptionBoxHeightCalculation = debounce(() => {
      const heightA = descriptionRefEssentialProbate.current?.offsetHeight || 0
      const heightB = descriptionRefComplexProbate.current?.offsetHeight || 0
      const descriptionBoxHeighest = Math.max(heightA, heightB)

      if (descriptionBoxHeighest !== descriptionBoxHeight) {
        setDescriptionBoxHeight(descriptionBoxHeighest)
      }
    }, 200)

    const restartHeight = debounce(() => {
      setDescriptionBoxHeight(undefined)
    }, 200)

    handleDescriptionBoxHeightCalculation()

    window.addEventListener('resize', restartHeight)
    window.addEventListener('resize', handleDescriptionBoxHeightCalculation)

    return () => {
      window.removeEventListener(
        'resize',
        handleDescriptionBoxHeightCalculation
      )
      window.removeEventListener('resize', restartHeight)
    }
  }, [descriptionRefEssentialProbate, descriptionRefComplexProbate])

  return (
    <StyledOptions direction="down" lightAccent thickEdge>
      <StyledTextureLeft
        path="textures/sketchy-swoop-yellow-03_k3g7yy"
        width={300}
      />
      <StyledTextureRight path="textures/detail_new_yellow02" width={400} />

      <Wrapper
        container
        containerPaddingBottom={0}
        style={{ position: 'relative' }}
        centeredFromM
      >
        <H tag="h2" size="L" decorative>
          Get specialist probate support
        </H>
        {description && (
          <P
            size="L"
            color={COLOR.BLACK}
            maxWidthInColumns={descriptionWidthInColumns}
            margin={[0, 'auto']}
          >
            {description}
          </P>
        )}
      </Wrapper>

      <Wrapper style={{ position: 'relative' }}>
        <StyledWrapper container>
          <Grid gap="S" gapFromM="L" gapFromL="XL">
            <StyledGridItem spanFromM={6}>
              <Option
                title="Grant of probate application"
                priceNote="From"
                price={PROBATE_SIMPLE_FEE}
                priceBottomNote="Fixed-fee pricing"
                description="Great if you’ve got the confidence and time to deal with the rest of the process."
                list={PROBATE_FEATURES.ESSENTIAL_PROBATE}
                descriptionBoxHeight={descriptionBoxHeight}
                ref={descriptionRefEssentialProbate}
              >
                <BreakpointSwitch
                  breakAt={BREAKPOINT.S}
                  belowComponent={<Divider margin={['XS', 0, 'L', 0]} />}
                />
                <StyledExtraInfo
                  borderedFromM
                  padding={0}
                  paddingFromM={['S', 'M']}
                  margin={['S', 0, 'M']}
                >
                  <StyledH tag="h5" size="XS" color={COLOR.GREY.DARK}>
                    You will need to:
                  </StyledH>{' '}
                  <P>{EXTRA_INFO.ESSENTIAL_PROBATE}</P>
                </StyledExtraInfo>
                <BreakpointSwitch
                  breakAt={BREAKPOINT.S}
                  belowComponent={<Divider margin={['L', 0]} />}
                />
                <StyledExtraCosts>
                  <StyledH tag="h5" size="XS" color={COLOR.GREY.DARK}>
                    Extra costs:
                  </StyledH>{' '}
                  <P>{EXTRA_COSTS.ESSENTIAL_PROBATE}</P>
                </StyledExtraCosts>
              </Option>
            </StyledGridItem>
            <Grid.Item spanFromM={6}>
              <Option
                title="Our complete probate service"
                priceNote="From"
                price={PROBATE_ESTATE_ADMIN_FEE}
                priceBottomNote="Fixed-fee pricing"
                description="Great if you’re busy, worried about making mistakes or if an impartial specialist could help avoid family conflict."
                list={PROBATE_FEATURES.COMPLETE_PROBATE}
                descriptionBoxHeight={descriptionBoxHeight}
                ref={descriptionRefComplexProbate}
              >
                <Divider margin={['S', 0, 'M']} />
                <StyledExtraCosts>
                  <StyledH tag="h5" size="XS" color={COLOR.GREY.DARK}>
                    Extra costs:
                  </StyledH>{' '}
                  <P>{EXTRA_COSTS.COMPLETE_PROBATE}</P>
                </StyledExtraCosts>
              </Option>
            </Grid.Item>
            <BreakpointSwitch
              breakAt={BREAKPOINT.S}
              belowComponent={
                <Grid.Item>
                  <QuoteCTASection />
                </Grid.Item>
              }
            />
          </Grid>
          <BreakpointSwitch
            breakAt={BREAKPOINT.S}
            aboveComponent={<QuoteCTASection />}
          />
        </StyledWrapper>
      </Wrapper>
    </StyledOptions>
  )
}

export default Options
