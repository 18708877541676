import { FLOW_PROBATE_URL } from 'config'

export const PRODUCT_OPTIONS_DESCRIPTION =
  'Our specialists take the stress out of probate, whether you just need support applying for probate or help handling the estate of someone who’s died.'

export const CARDS_DATA = {
  title: 'How we help you save',
  cards: [
    {
      title: 'Streamlined \n process',
      content: 'We use technology to cut down on paperwork and admin time',
      image: 'b_auto,c_fill_pad,g_auto,h_400,w_550/illustrations/checks-passed',
    },
    {
      title: 'Prices are transparent and fixed upfront',
      content: 'So you don’t have to worry about hidden costs or hourly rates',
      image:
        'b_auto,c_fill_pad,g_auto,h_400,w_550/illustrations/square/money-with-lock',
    },
    {
      title: 'You’ll get dedicated support',
      content:
        'You’ll get regular updates so you know what’s happening at every step.',
      image: 'c_pad,h_400,w_550/funerals/celebrations/dedicated_coordinator',
    },
    {
      title: 'No face-to-face appointments',
      content:
        'We’ll make sure everything can be done from the comfort of your own home.',
      image: 'c_fill,h_400,w_550/illustrations/phone-home-comforts-probate',
    },
  ],
  ctaSection: {
    title: 'Get a free quote online',
    ctaText: 'Get a quote in 5 minutes',
    ctaTo: FLOW_PROBATE_URL,
  },
}
