const data = {
  title: 'Get started with one, quick phone call',
  OutOfHoursTitle: 'Prefer to talk?',
  description: `In just 5 minutes, one of our friendly specialists will help you work out:`,
  image: 'illustrations/cropped/phone-with-hand',
  checklistItems: [
    'Whether you need probate',
    'How much it’ll cost to get sorted',
    'What information you’ll need for the application',
  ],
}

export default data
