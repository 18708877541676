import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { Grid, H, P, Wrapper, Image, Button } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { isInternalURL } from 'lib/url/isInternalURL'

const StyledGrid = styled(Grid)`
  grid-auto-rows: 1fr;
`
const CardWrapper = styled(Wrapper)`
  border-radius: ${GTR.S};
  height: 100%;
`
const StyledButton = styled(Button.Primary)`
  ${screenMin.m`
    max-width: 290px;
  `}
`

const StyledImageWrapper = styled(Wrapper)`
  img {
    max-width: 260px;
  }
`

type CardData = {
  title: string
  content?: string
  image: string
}

interface CtaSectionProps {
  title?: string
  subtitle?: string
  ctaTo: string
}

interface CardsProps {
  data: {
    title: string
    subtitle?: string
    cards: CardData[]
    ctaSection?: CtaSectionProps
  }
}
const CtaSection = ({
  title,
  subtitle,
  ctaTo,
}: CtaSectionProps): React.ReactElement => (
  <>
    {title && (
      <H size="S" tag="h3">
        {title}
      </H>
    )}
    {subtitle && (
      <P maxWidthInColumns={5} marginFromM={[0, 'auto', 'M']}>
        {subtitle}
      </P>
    )}
    <StyledButton
      to={ctaTo}
      href={ctaTo}
      tag={isInternalURL(ctaTo) ? Link : 'a'}
      forward
      stretch
    >
      Get a quote in 5 minutes
    </StyledButton>
  </>
)

const FourCards = ({ data }: CardsProps): React.ReactElement => (
  <Wrapper container centeredFromM>
    <H size="L" tag="h2" decorative>
      {data.title}
    </H>
    {data.subtitle && (
      <P size="L" maxWidthInColumns={6} margin="auto">
        {data.subtitle}
      </P>
    )}
    <StyledGrid gapFromL="M" gapFromXL="XL" padding={['XL', 0]}>
      {data.cards.map((card) => (
        <Grid.Item key={card.title} spanFromM={6} spanFromL={3}>
          <CardWrapper
            padding="M"
            paddingFromL={['S', 'S']}
            paddingFromXL={['L', 0, 'XL']}
            background={COLOR.ACCENT.PRIMARY_10}
          >
            <StyledImageWrapper>
              <Image path={card.image} width={550} stretch />
            </StyledImageWrapper>
            <Wrapper
              paddingFromS={['S', 0, 0]}
              paddingFromL={['S', 0]}
              paddingFromXL={['M', 'M', 0]}
            >
              <H size="XS">{card.title}</H>
              {card.content && <P>{card.content}</P>}
            </Wrapper>
          </CardWrapper>
        </Grid.Item>
      ))}
    </StyledGrid>
    {data.ctaSection && (
      <CtaSection
        title={data.ctaSection.title}
        subtitle={data.ctaSection.subtitle}
        ctaTo={data.ctaSection.ctaTo}
      />
    )}
  </Wrapper>
)

export default FourCards
