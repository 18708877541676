import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { BulletedList, TickIcon, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

const StyledListItem = styled.li`
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: ${GTR.XS};
  }

  &::before {
    position: absolute;
    content: '';
    left: -40px;
    top: 10px;
    width: 8px;
    height: 8px;
    display: block;
    background: ${COLOR.ACCENT.PRIMARY};
    border-radius: 50px;
  }
`

type DecorativeListProps = {
  listItems: {
    title: string
    items: string[]
  }[]
}

const DecorativeList = ({ listItems }: DecorativeListProps): ReactElement => (
  <BulletedList
    headingSize="XS"
    icon={<TickIcon background={COLOR.ACCENT.PRIMARY} color={COLOR.BLACK} />}
    gap="S"
  >
    {listItems.map(({ title, items }) => (
      <BulletedList.Item key={title} title={title}>
        {items.length > 0 && (
          <Wrapper tag="ul" padding={['S', 0, 0]}>
            {items.map((item) => (
              <StyledListItem key={item}>{item}</StyledListItem>
            ))}
          </Wrapper>
        )}
      </BulletedList.Item>
    ))}
  </BulletedList>
)

export default DecorativeList
